import React from "react";
import { Value } from "../About/Values";
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import { IoPlayBack, IoPlayForward } from "react-icons/io5";
const Brief = () => {
  const settings = {
    className:
      "center border-2 border-black text-center overflow-x-scroll w-auto flex",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    nextArrow: <IoPlayForward />,
    prevArrow: <IoPlayBack />,
    speed: 500,
    accessibility: true,
    arrows: true,
    dotsClass: "slick-dots",
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 4,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="py-5 px-2 desktop:px-[10px]  mobile:my-14 h-[60vh] my-14">
      <div className="flex flex-col mobile:flex-col smtab:flex-col Tablet:flex-col">
        <div className="text-center">
          <h1 className="text-[32px] my-4 font-medium">Our Values</h1>
        </div>
        <div className="slider-container flex items-center justify-center">
          <Slider {...settings} className="mobile:w-[90%] w-full">
            {Value.map((item, index) => (
              <div key={index} className="w-full">
                <div className="flex flex-col items-center justify-between ">
                  <div className="h-[50%] w-[50%] ">
                    <img src={item.icon} alt="imgg" className="object-fit h-full w-full" />
                  </div>

                  <div className="text-center my-10 w-[80%]">
                    <p className="text-sm font-light">{item.text}</p>
                    <p className="mobile:text-[14px]">{item.submain}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Brief;
