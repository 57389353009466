import React from "react";
import memag from "../../Assets/logo.png";
const Banner = () => {
  return (
    <div className=" flex flex-col items-center justify-center bg-accent p-4 h-[100vh] gap-5">
      <img src={memag} className="" alt="iswis" />

      <h1 className="text-[36px]  text-center mobile:text-[36px] smtab:text-[48px] text-white">
        Nifinspired
      </h1>
      <p className="text-center text-[18px] leading-9 mobile:text-[14px] w-[60%] mobile:w-[100%] text-white">
        Welcome to NifInspired , your ultimate destination for luxurious, organic skincare products.
        Our journey began with a passion for harnessing the power of nature to nurture your skin.
      </p>
    </div>
  );
};

export default Banner;
