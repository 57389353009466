import React, { useState, useEffect } from "react";
import Input from "../Resources/Input";
import Loader from "../Components/Loader/Loader";
import call from "../Assets/marcus.jpg";
import Button from "../Resources/Button";
import { addContactUs } from "../Services/request";

const Contact = () => {
  const [changing, setChanging] = useState(false);
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);
  const [contactFields, setContactFields] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleContact = (e) => {
    let value = e.target.value;
    let name = e.target.name;

    setContactFields({ ...contactFields, [name]: value });
    setChanging(!changing);
  };

  const handleReport = async (e) => {
    e.preventDefault();
    if (valid) {
      setLoading(true);
      await addContactUs(contactFields);
      setLoading(false);
      setContactFields({
        name: "",
        email: "",
        phone: "",
        message: "",
      });
    }
  };

  useEffect(() => {
    if (
      contactFields["name"] !== "" &&
      contactFields["email"] !== "" &&
      contactFields["phone"] !== "" &&
      contactFields["message"] !== ""
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [changing]);

  return (
    <>
      <div className="h-[70vh] flex items-center justify-center bg-accent flex-col">
        <h1 className="text-[64px] text-center mobile:text-[32px] text-white ">Contact Us</h1>
        <p className="text-white">
          Submit your questions or request in the form below and we will respond as soon as possible
        </p>
      </div>
      <div className="font-poppins my-[100px] mobile:px-[10px] smtab:px-[20px] px-[150px] Tablet:px-[20px] desktop:px-[100px]">
        {/**the Input fields */}
        <div className="flex items-center justify-center gap-5 justify-">
          <form
            className="w-full my-[100px] flex flex-col items-center mobile:items-center"
            onSubmit={handleReport}
          >
            <Input
              type="text"
              name="name"
              placeholder="Fullname"
              value={contactFields["name"]}
              onChange={handleContact}
              class="border-b w-full text-[18px] px-5 py-3 my-5 outline-none"
            />

            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={contactFields["email"]}
              onChange={handleContact}
              class="border-b w-full text-[18px] px-5 py-3 my-5 outline-none"
              required
            />

            <Input
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={contactFields["phone"]}
              onChange={handleContact}
              class="border-b w-full text-[18px] px-5 py-3 my-5 outline-none"
              required
            />

            <textarea
              placeholder="How can we help you?"
              name="message"
              value={contactFields["message"]}
              onChange={handleContact}
              className="border w-full text-[18px] px-5 py-3 my-5 resize-y h-[200px]"
              required
            />

            <Button
              name={
                loading ? (
                  <div className="flexmm gap-[10px]">
                    <Loader />
                    <p>Submit</p>
                  </div>
                ) : (
                  <p>Submit</p>
                )
              }
              type="submit"
              class={
                valid
                  ? "px-20 py-5 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all hover:bg-[#205550]"
                  : "px-20 py-5 bg-[grey] m-10 font-bold text-[#ffffff] rounded-full text-sm cusor-not-allowed"
              }
              disable={valid ? false : true}
            />
          </form>

          <div className="w-full h-[700px] border mobile:hidden flex items-center justify-center relative overflow-hidden">
            <img src={call} className="object-cover h-full w-full " />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
