import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Resources/Button";

import { IoArrowDown } from "react-icons/io5";
import { IconContext } from "react-icons";
import { HashLink } from "react-router-hash-link";
const Hero = () => {
  const navigate = useNavigate();
  return (
    <div className="relative left-0 right-0 font-poppins h-[100vh] flex justify-start items-center mobile:items-end  w-full mobile:h-[80vh] mobile:gap-10 smtab:h-[100vh] mobile:flex-col-reverse">
      <div className="flex flex-col justify-center gap-0 h-auto  mobile:h-[100%] mobile:mt-10  smtab:h-[50%] desktop:py-10 absolute w-full  ">
        {/**the heroa section content */}

        <div className=" text-center flex  flex-col items-center">
          <p className="text-[72px] my-[5px] mobile:text-[42px] smtab:text-[32px] Tablet:text-[42px]">
            Quality{" "}
            <span className="text-accent ">
              Skincare <br />
            </span>{" "}
            made with <span className="text-accent">care</span>
          </p>

          <p className="text-[18px] mobile:text-[14px] large:text-[18px] w-[60%] mobile:w-[90%]">
            Transform your skincare routine and reveal your most radiant self with us today. Shop
            Now & Experience the Difference
          </p>
        </div>
        <div className="flex items-center flex-col my-5">
          <Button
            name="Browse our Collection"
            onClick={() => navigate("/shop")}
            class="my-[20px] px-9  mobile:px-6  mobile:py-3 py-4 bg-accent m-10 font-bold text-[#ffffff] rounded-full text-sm transition-all text-[14px] hover:bg-white hover:text-accent border-2 hover:border-2 border-accent shadow- animate-bounce"
          />
          <HashLink
            smooth
            to="#products"
            className="flex items-center cursor-pointer hover:font-semibold transition-all duration-300"
          >
            <p className="mx-1">Buy now</p>
            <IconContext.Provider value={{ size: "15px" }}>
              <IoArrowDown />
            </IconContext.Provider>
          </HashLink>
        </div>
      </div>
    </div>
  );
};

export default Hero;
