import React, { useState, useEffect } from "react";
import {
  NavLink,
  Outlet,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import nif from "../Assets/Nifnewweb.png";
import { IoIosArrowDown } from "react-icons/io";
import { AiOutlineUser } from "react-icons/ai";
import { IoCartOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import Pagefoot from "./Pagefoot";

//this is where the Navigation links would be...

export const NavData = [
  {
    title: "Home",
    path: "/",
  },

  {
    title: "About",
    path: "/about",
  },

  {
    title: "Blog",
    path: "/Blog",
  },

  {
    title: "Contact",
    path: "/contact",
  },
  {
    title: "Shop",
    path: "/shop",
  },
];

export default function RootLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState("");
  const [products, setProducts] = useState([]);
  // eslint-disable-next-line
  const [loggedIn, setLoggedIn] = useState(false);
  // eslint-disable-next-line
  const [userId, setUserId] = useState("");
  const [width, setWidth] = useState(window.innerWidth);

  const handleWidth = () => {
    setWidth(window.innerWidth);
    if (width >= 768) {
      setShow(false);
    }
  };
  window.addEventListener("resize", handleWidth);

  const showMenu = () => {
    setShow(!show);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("nifInspiredUser"));
    const token = localStorage.getItem("nifInspiredToken");
    if (token && user) {
      setLoggedIn(true);
      setUserData(user);
    }

    // localStorage.removeItem("accessedProduct");
    let cartProduct = localStorage.getItem("cart");
    if (cartProduct) {
      setProducts(JSON.parse(cartProduct));
    } else {
      setProducts([]);
    }
  }, []);
  return (
    <div className=" font-poppins relative ">
      <nav className="mobile:px-[20px] smtab:px-[20px] px-[150px] Tablet:px-[20px] desktop:px-[100px]  py-3 mobile:sticky   flex items-center justify-between text-[11px] bg-white z-50 sticky  w-full top-0">
        <div className=" flex items-center justify-between mobile:w-full smtab:w-[100%] ">
          <Link to="/">
            <img
              src={nif}
              alt="logo"
              className="h-14 lg:h-[90px]  mobile:h-[70px] smtab:h-[70px] Tablet:h-[70px]"
            />
          </Link>

          <div className="overflow-hidden hidden mobile:block smtab:block  " onClick={showMenu}>
            <div
              className={
                show
                  ? "h-[3px] w-6 bg-[#000000]  rounded-full rotate-45 -translate-y-[-8px] ease-in-out duration-1000"
                  : "h-[3px] w-6 bg-[#000000] my-1 rounded-full ease-in-out duration-1000"
              }
            ></div>
            <div
              className={
                show
                  ? "h-[3px] w-6 bg-[#000000] my-1 rounded-full -translate-x-20 "
                  : "h-[3px] w-6 bg-[#000000] my-1 rounded-full ease-in-out duration-1000"
              }
            ></div>
            <div
              className={
                show
                  ? "h-[3px] w-6 bg-[#000000] my-1 rounded-full rotate-[315deg] translate-y-[-6px] ease-in-out duration-1000"
                  : "h-[3px] w-6 bg-[#000000] my-1 rounded-full ease-in-out duration-1000"
              }
            ></div>
          </div>
        </div>

        <div
          className={
            show
              ? " bg-[#ffffff] absolute  w-full top-[100%] left-0 flex flex-col items-end text-[18px]  smtab:top-20 smtab:left-0r h-[100vh] z-[100000] border"
              : " w-[70%] mobile:hidden flex  relative  justify-between mobile:w-[100%] md1:w-[80%] smtab:hidden  large:w-[67%]"
          }
        >
          <ul className="flex mobile:flex-col justify-evenly items-center  mobile:w-full smtab:w-full ">
            {NavData.map((items, index) => {
              return (
                <li
                  key={index}
                  className="group  py-2 w-auto px-6 flex flex-col items-start justify-between

                                 my-3  mobile:text-[14px] smtab:text-[14px] desktop:text-[16px] large:text-[18px]"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <NavLink
                    to={items.path}
                    className="uppercase transition ease-in-out duration-1000"
                  >
                    {items.title}
                  </NavLink>
                  <div
                    className={
                      items.path === location.pathname
                        ? "h-[2px] w-6 bg-[#000000]  transition ease-in duration-1000 "
                        : "h-[2px] w-0 bg-black group-hover:w-[70%] ease-in-out duration-1000"
                    }
                  ></div>
                </li>
              );
            })}
          </ul>

          <div className=" flex items-center justify-center  w-[auto] mobile:w-[100%] my-4 lg:text-[12px] ls:text-[11px] smtab:mx-[20px] smtab:text-[14px]">
            <div className="relative flex items-center  border-r-2 border-black px-2 mx-1 group cursor mobile:text-[14px] desktop:text-[16px] large:text-[18px] b">
              <p
                className="uppercase  px-1 cursor-pointer text-[14px]"
                onClick={() => {
                  !loggedIn && navigate("/login");
                }}
              >
                {loggedIn ? userData.name : "Sign in"}
              </p>

              {loggedIn && (
                <>
                  <div>
                    <IoIosArrowDown />
                  </div>
                  <IconContext.Provider value={{ size: "20px" }}>
                    <div>
                      <AiOutlineUser />
                    </div>
                  </IconContext.Provider>

                  <ul className="hidden absolute top-[110%] left-[-20%] w-[170%] border group-hover:block border-black bg-white">
                    <li className="p-2 hover:bg-accent hover:text-[#ffffff] cursor-pointer">
                      <NavLink to="delivery">Order & Delivery</NavLink>
                    </li>
                    <li className="p-2 hover:bg-accent hover:text-[#ffffff] cursor-pointer">
                      <NavLink to="/usage">Usage Instructions</NavLink>
                    </li>
                    <NavLink to="/login">
                      <li
                        className="p-2 hover:bg-accent hover:text-[#ffffff] cursor-pointer"
                        onClick={() => {
                          localStorage.removeItem("nifInspiredUser");
                          localStorage.removeItem("nifInspiredToken");
                          localStorage.removeItem("cart");
                        }}
                      >
                        <p>Logout</p>
                      </li>
                    </NavLink>
                  </ul>
                </>
              )}
            </div>
            <NavLink to="/cart">
              <IconContext.Provider value={{ size: "25px" }}>
                <div className="cursor-pointer relative">
                  <IoCartOutline />
                  {products.length > 0 && (
                    <div className="absolute -top-2 -right-2 bg-accent text-white text-[11px] w-[22px] h-[22px] flexmm rounded-full">
                      <p>{products.length}</p>
                    </div>
                  )}
                </div>
              </IconContext.Provider>
            </NavLink>
          </div>
        </div>
      </nav>

      <main className=" h-auto w-full">
        <Outlet context={[products, setProducts]} />
      </main>
      <Pagefoot />
    </div>
  );
}
