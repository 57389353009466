import React from "react";
import Hero from "../Components/Homepage/Hero";
import Brief from "../Components/Homepage/Brief";
import Category from "../Components/Homepage/Category";
import ProductCategory from "../Components/Homepage/ProductCategory";
import Choose from "../Components/Homepage/Choose";
import BlogWidget from "../Components/Homepage/BlogWidget";
const Home = () => {
  return (
    <div className="Home ">
      <Hero />
      <div className="mobile:px-[15px] smtab:px-[20px] px-[150px] Tablet:px-[20px] desktop:px-[100px]">
        <Brief />
        <Category />
        <div id="products">
          <ProductCategory />
        </div>
        <Choose />
        <BlogWidget />
      </div>
    </div>
  );
};

export default Home;
