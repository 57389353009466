import React from "react";

const Usage = () => {
  return (
    <div className="p-5">
      <div className="my-14 text-[46px] mobile:my-5">
        <p>Usage Instructions</p>
      </div>

      <div className="my-5">
        <div className="my-10">
          <p className="font-semibold">Organic Skincare Body Oil:</p>
          <ol className="px-10 list-decimal">
            <li className="my-3">After showering or bathing, pat your skin dry with a towel.</li>
            <li className="my-3">Take a small amount of the body oil in your hands.</li>
            <li className="my-3">
              {" "}
              Gently massage the oil onto your skin using upward circular motions.
            </li>
            <li className="my-3">
              Focus on areas that need extra hydration, like elbows, knees, and ankles.
            </li>
            <li className="my-3">
              Allow the oil to absorb into your skin for a few minutes before getting dressed.
            </li>
          </ol>
        </div>

        <div className="my-10">
          <p className="font-semibold">Organic Skincare Body Butter:</p>
          <ol className="px-10 list-decimal">
            <li className="my-3">
              Take a small amount of the body butter using clean fingers or a spatula.
            </li>
            <li className="my-3">Warm the product slightly by rubbing it between your palms.</li>
            <li className="my-3">
              Apply the body butter to clean, dry skin after a bath or shower.
            </li>
            <li className="my-3">
              Massage it into your skin using gentle, upward strokes until fully absorbed.
            </li>
            <li className="my-3">
              Pay extra attention to dry or rough areas for deep nourishment.
            </li>
          </ol>
        </div>

        <div className="my-10">
          <p className="font-semibold">Organic Skincare Body Scrub:</p>
          <ol className="px-10 list-decimal">
            <li className="my-3">
              Use the body scrub 2-3 times a week during your shower routine.
            </li>
            <li className="my-3">Wet your skin thoroughly with warm water.</li>
            <li className="my-3">Take a generous amount of the scrub and apply it to your skin.</li>
            <li className="my-3">
              Gently massage in circular motions, focusing on rough or dry areas.
            </li>
            <li className="my-3">Rinse off the scrub with warm water, then pat your skin dry.</li>
            <li className="my-3">Follow up with a moisturizer to lock in hydration. </li>
          </ol>
        </div>

        <div className="my-10">
          <p className="font-semibold">Organic Skincare Bath Soap:</p>
          <ol className="px-10 list-decimal">
            <li className="my-3">Wet your body under the shower or in the bath.</li>
            <li className="my-3">Lather the soap in your hands or on a washcloth.</li>
            <li className="my-3">
              Apply the lather to your skin, gently massaging in circular motions.
            </li>
            <li className="my-3">Rinse off the soap thoroughly with warm water.</li>
            <li className="my-3">
              Pat your skin dry with a towel, and follow up with a moisturizer if needed.
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Usage;
