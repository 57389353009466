import React from 'react'
import { IconContext } from 'react-icons';
import { FaHeadphones, FaTruck, FaStar } from "react-icons/fa6";

const Choose = () => {
  return (
    <div className="my-[100px]">
      <div className="text-center">
        <h1 className="font-bold text-[32px] mobile:text-[24px]">
          Why People <span className="text-accent">Choose Us</span>
        </h1>
        <p className="my-3 text-[18px] mobile:text-[14px]">
          Our Customers choose us for quality products, visible results, personalized solutions, and
          exceptional customer service. <br />
          Experience radiant skin with our luxurious, organic skincare products.{" "}
        </p>
      </div>

      <div className="flex items-center justify-between my-[100px] w-full mobile:flex-col smtab:flex-wrap ">
        <IconContext.Provider value={{ size: "70px", color: "#066B63" }}>
          <div className=" border-black flex flex-col items-center w-full">
            <FaHeadphones />
            <p className="w-[300px]  text-center my-10 mobile:text-[14px] Tablet:w-full">
              Our customer service is exceptional, personalized, attentive, friendly, and reliable.{" "}
            </p>
          </div>

          {/**section reason */}
          <div className="flex flex-col items-center w-full">
            <FaTruck />
            <p className="w-[300px]  text-center my-10 mobile:text-[14px] Tablet:w-full">
              Very Fast and reliable delivery ensures your products arrive promptly every time .{" "}
            </p>
          </div>

          <div className="flex flex-col  items-center w-full">
            <FaStar />
            <p className="w-[300px] text-center my-10 mobile:text-[14px] Tablet:w-full">
              Premium ingredients and advanced formulations for exceptional skincare results.
            </p>
          </div>
        </IconContext.Provider>
      </div>
    </div>
  );
}

export default Choose
