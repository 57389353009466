import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchCategories } from "../../Services/request";
import { IoArrowForward } from "react-icons/io5";

const Category = () => {
  const [categories, setCategories] = useState([]);

  const fetchProductCategories = async () => {
    let data = await fetchCategories();
    setCategories(data);
  };

  useEffect(() => {
    fetchProductCategories();
  }, []);

  return (
    <div className="w-full my-[100px] mobile:my-5">
      <div className="flex items-center justify-between">
        <p className="font-bold text-[24px]">Product Category</p>
      </div>

      <div className="w-full justify-between flex gap-[30px] items-center my-[50px] mobile:flex-col smtab:flex-wrap smtab:justify-center mobile:gap-2 ">
        {categories?.map((items) => (
          <Link
            key={items.id}
            to={`/category/${items.name}`}
            className="group relative h-[300px] w-[400px] my-3 overflow-hidden rounded-xl flex items-center justify-center bg-accent   transition-all smtab:mx-[20px] Tablet:mx-[10px] mobile:w-[100%] mobile:mx-0 smtab:w-full mobile:h-[200px] mobile:my-0"
          >
            {/* <img
              src={items.image}
              alt="images"
              className="object-cover w-full h-full z-[-100] right-50 object-right"
            /> */}
            <div className="absolute top-50% left-50%  cflexmm gap-[10px] text-center hover:duration-1000">
              <h1 className="text-[56px] font-bold text-white capitalize leading-none">
                {items.name}
              </h1>
              <p className="text-white">Nifinspired</p>

              <div className="border border-white rounded-full p-1">
                <div className=" items-center gap-2 text-[13px] hidden group-hover:flex  bg-white py-1  px-2 rounded-full">
                  <span className="text-accent">view {items.name.toLowerCase()}</span>
                  <IoArrowForward className="text-accent" />
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Category;
