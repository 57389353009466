import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import logo from "../Assets/logo.png";

import BlogList from "../Components/BlogComponents/BlogLists";
import { getBlogs } from "../Services/request";

const Blog = () => {
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const getAllBlogs = async () => {
    setLoading(true);
    let data = await getBlogs();
    //
    if (data) {
      setLoading(false);
      setBlogs(data);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, []);

  return (
    <div className="mobile:px-[10px] smtab:px-[20px] px-[150px] Tablet:px-[20px] desktop:px-[100px]">
      <div className="h-[50vh] mt-[10px] mobile:mt-[10px] mobile:h-[50vh] flex flex-col items-center justify-center">
        <img src={logo} alt="lofos" className="h-24" />
        <p className="text-[64px] text-center mobile:text-[36px] Tablet:text-[48px]">
          BLOG
          {loading && <BeatLoader />}
        </p>
        {/* <div className=' h-[600px] overflow-hidden my-10 rounded-xl mobile:h-full'>
            <img src={retro} className=''/>
        </div> */}
      </div>
      <div className="my-10">
        <BlogList blogInfo={blogs} />
      </div>
    </div>
  );
};

export default Blog;
